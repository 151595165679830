:root {
  --bg-main: #063284;
  --bg-main-adjacent: darkblue;
  --primary-btn-text: lightseagreen;
  --primary-btn-bg: #faf3ea;
  --primary-btn-alt-bg: antiquewhite;
  --secondary-btn-bg: rgb(156, 217, 156);
  --sidenav-bg: #2c75ff;
}

body {
  margin: -23px 0;
  font-family: 'EB Garamond', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

