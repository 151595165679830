body,
.App{
  height:100%;
  background: #063284;
  width:100%
}

.row{
  display: flex;
}

.column{
  display: flex;
  flex-direction: column;
}

.side-nav{
  list-style: none;
  font-weight: 500;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: flex-start;
  background-color: var(--sidenav-bg);
  width: 220px;
  margin-left: -40px;
}
.tabs{
  position:absolute;
  padding: 10px;
  height: 30px;
  top:0px;
  left: 0px;
  right: 0px;
  background-image: linear-gradient(rgb(243, 216, 166),var(--primary-btn-bg));
  display: flex;
  justify-content: space-around;
  
}.tab{
  width: 200px;
  padding: 3px;
  text-align: center;
  border-top:solid 1px var(--bg-main);
  border-bottom: solid 1px var(--bg-main);
  cursor: pointer;
  color: darkmagenta
}
.side-nav li {
  cursor: pointer;
}
.signup-btn-line{
  color:var(--primary-btn-alt-bg)
}
.form-line{
  width: 300px;
}
.page{
  background: var(--primary-btn-bg);
  padding: 15px 40px;
  position:absolute;
  bottom:0;
  left:240px;
  top:256px;
  right: 0;
  overflow-y: scroll;
}
.full-page{
  left: 20px;
}
.bg-black{
  background-color: black;
}
.unclickable{
  color: lightgray;
  background: darkmagenta;
  width: 220px;
}

.clickable{
  cursor: pointer;
  color: white;
}
.header-select-wrapper{
  width: 100vw;
  display:flex;
  justify-content: center;
}
.header-container{
  padding:10px 10px 15px;
  color: var(--primary-btn-text);
  text-shadow: .5px -1px lightcyan;
  font-size: larger;
}

.header-select{
  height: 32px;
  padding-top:10px;
  border: solid 1px lightgray;
  width: 100%;
  text-align: center;
  border-radius: 2px;
}
button{
  cursor: pointer;
  background: var(--primary-btn-bg);
  border-width: .5px;
  color: darkslategray;
  padding:5px 10px;
  color:  var(--primary-btn-text);
  text-shadow: .5px -1px lightcyan;
  font-size: larger;
  font-family: 'EB Garamond', serif;
  font-weight: 500;
}
nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: 500;
  height:24px;
}
.nav-button{
  border-left:7px solid #063284;
  border-top: solid 7px var(--primary-btn-alt-bg);
  border-right:7px solid #063284;
  border-bottom: solid 7px var(--primary-btn-alt-bg);
  width: 200px;
  border-radius:8px;
}
.collapsed-side-nav{
  margin-left:-225px;
}
.center{
  align-items: center;
}

.header-select-container{
  background: var(--bg-main-adjacent);
  width: 350px;
  position: absolute;
  z-index: 2;
  margin:auto;
}
.italic{
  font-style: italic;
}
.b-shadow-white{
  box-shadow: inset 0 0 10px white;
}
.b-shadow-lightseagreen{
  box-shadow: inset 0 0 10px var(--primary-btn-text);
}
.underline {
  text-decoration: underline;
}
.signup-button{
  position: absolute;
  top:4px;
  right:10px;
  margin-right:3%;
  margin-top:1%;
  border-radius: 5px;
  padding:10px;
  font-size: 15px;
}
.overlay{
  display: flex;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background: rgb(0,0,0,.2);
  z-index:2;
  justify-content: center;
  align-items: center;
}
.signup-form{
  width: 350px;
  height: 520 px;
  background-color: whitesmoke;
  border-radius: 5px;
  border: solid 1px white;
}
.success-box{
  width: 350px;
  height: 200px;
  background-color: whitesmoke;
  border-radius: 5px;
  border: solid 1px white;
}
.fail-box{
  width: 350px;
  height: 180px;
  background-color: whitesmoke;
  border-radius: 5px;
  border: solid 1px white;
}
.fail-box h1, .fail-box p, .success-box h1, .success-box p {
  padding-left: 10px
}
.form-top{
  display: flex;
  justify-content: space-between;
  background: darkslateblue;
  color: white;
  padding:10px 8px 9px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid white;
  font-size: large;
}
.button{
  cursor: pointer
}

.fields-container{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  height:512px;
  overflow-y: scroll;
}
.fields-container div{
  display:flex;
  justify-content: space-between;
  padding: 10px 8px;
}
.fields-container div input {
  width: 200px;
  height: 25px;
}
.fields-container button {
  position: relative;
  bottom: 0px;
  font-size:25px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.fields-container div select{
  height: 25px;
}
input:focus {
  background-color: rgb(206, 218, 240);
}

svg{
  height: 130px;
  margin-top: 10px;}
.img-body{
  display: flex;
}

.header-h1{
  font-size: 55px;
  margin-bottom:-12px;
}

.days-form{
  display: flex;
  flex-direction: column;
}
@media (min-width: 440px) and (max-width: 1220px) {
  .page{
    top:310px;
  }
  .header-container{
    margin-bottom: 55px;
  }
}
@media (max-width: 440px) {
  .header-h1{
    margin: 38.5px -40px;
    font-size: 18px;
  }
  .header-h3{
    margin:30px -40px;
    font-size: 14px;
  }
  .page-p{
    margin-left:5%;
    font-size:20px;

  }
}
@media (min-width: 440px) and (max-width: 550px) {
  .header-h1{
    margin: 38.5px -40px;
    font-size: 28px;
  }
  .header-h3{
    margin:30px -40px;
    font-size: 18px;
    margin-left:5%
  }
  .page-p{
    margin-left:5%;
    font-size:18px;
  }
}

@keyframes loader {
  from {background-color: rgba(255, 255, 255, 0.2);}
  to {background-color: rgb(0, 0, 0,.2);}
}

#loading {
  width:100vw;
  height: 100vh;
  background-color: rgb(128, 128, 128,.2);
  animation-name: loader;
  animation-duration: 1s;
  z-index:3
}
.bg-valid{
  background: rgb(199, 234, 199) !important;
}
.bg-error{
  background: rgb(230, 169, 169);
}

.disabled-button{
  background-color: gainsboro;
  cursor: not-allowed;

}
.submit-button{
  background: var(--secondary-btn-bg);
  color:white;
  margin:0px !important;
  width:350px !important;
  height:43px;
}
.disabled-tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  padding: 0px !important;
}
.disabled-tooltip button{
  margin:0px !important;
  width:350px !important;
  height:43px;
}

/* Tooltip text */
.disabled-tooltip .disabled-notification {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  top: -28px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}
.disabled-notification{
    width: 180px;
}
/* Show the tooltip text when you mouse over the tooltip container */
.disabled-tooltip:hover .disabled-notification {
  visibility: visible;
}
.page-h1{
  font-size:40px;
  margin:45px 0 0;
  color: rgb(209, 41, 41);
}

.page-h3{
  font-size:22px;
  font-style: italic;
  margin-left:8%;
  color: gray;
}

.page-p{
  margin-left:3%;
  font-size:20px;
}